@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(10px);
	z-index: 1000;
	color: $black;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&[data-visible='false'] {
		opacity: 0;
		pointer-events: none;

		.content {
			transform: scale(0.95) translateY(20px);
		}
	}

	&[data-visible='true'] {
		opacity: 1;
		pointer-events: auto;

		.content {
			transform: scale(1) translateY(0);
		}
	}

	transition: opacity 0.2s ease-in-out;
}

.content {
	background-color: $background-white;
	padding: 32px;
	border-radius: $br-sm;

	min-width: min(50vw, 45rem);
	max-width: min(45rem, 90vw);

	transition: transform 0.2s ease-in-out;
}

.content header {
	margin-block-end: 16px;
}

.address {
	display: flex;
	flex-direction: column;
	gap: 2px;

	flex: 1;
}

.address_box {
	flex: 1;
	padding: 16px;
	border-radius: $br-sm;
	border: 1px solid $border-grey-light;

	display: flex;
	flex-direction: column;
	gap: 16px;

	justify-content: space-between;
}

.addressContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.close {
	position: absolute;
	top: 32px;
	right: 32px;
}

.address_buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}
