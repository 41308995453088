@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors.scss';

.priceblock {
	color: #000;
	width: 100%;
	margin-top: 0;
	background-color: $ui-light-grey;
	margin-bottom: 0;
	border-radius: 5px;
}

.grayblock {
	height: 28px;
}

.pricestyle {
	display: inline-block;
	margin-left: 6px;
	@include h7-desktop;

	@include xs-only {
		font-size: 14px;
	}
}

.cad_tag {
	font-size: 12px;
	opacity: 0.7;
	margin-top: 2px;
}

.pay_over_time_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
}

.cad_tag {
	font-size: 12px;
	opacity: 0.7;
	margin-top: 2px;
}

.pay_over_time_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
}

.originalprice {
	composes: pricestyle;
	text-decoration: line-through;
	opacity: 0.3;
	margin: 0 6px;
	@include h7-desktop;

	@include xs-only {
		font-size: 14px;
	}

	@media (max-width: 374px) {
		display: none;
	}
}

.moneyline {
	border-top: 1px solid #fff;
	display: grid;
	position: relative;
	grid-template-columns: 1fr 1px 1fr;
	text-align: center;
	padding: 28px;

	&.banner_financing {
		grid-template-columns: 1fr min-content 1fr;
		padding: 15px;
	}

	&.moneyline_financing {
		padding: 22px 0;
	}
}

.priceflex {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.moneyleft,
.moneyright {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
}

.moneyright {
	flex-wrap: wrap;
}

.moneytitle {
	line-height: 12px;
	margin-bottom: 6px;
	font-size: 12px;
	color: #000;
	font-weight: bold;
	@include xs-only {
		margin-bottom: 0;
		font-size: 10px;
	}
}

.moneydivider {
	display: flex;
	align-items: center;
	justify-content: center;
}

.moneyborderline {
	height: 90%;
	width: 0;
	border-left: 1px rgba(0, 0, 0, 0.1) solid;
	position: relative;

	&:after {
		content: 'OR';
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 15px;
		position: absolute;
		background: $ui-light-grey;
		left: -10px;

		@include sm-up {
			top: 10px;
		}

		@include xs-only {
			top: 8px;
		}

		font-size: 12px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.7);
	}
}

.banner {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #fff;
	padding: 25px;
	background-color: #edeef1;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	&.banner_financing {
		padding: 8px 15px;
		border-bottom: 1px solid #e5e5e5;
	}
}

.bannertext {
	color: $brand-secondary;
	text-align: center;
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 0;
	display: inline-block;

	@include xs-only {
		font-size: 12px;
	}
}

.line {
	width: 1px;
	height: 20px;
	opacity: 0.5;
	background-color: #000 !important;
	position: absolute;
}

.topline {
	composes: line;
	top: 0;
}

.bottomline {
	composes: line;
	bottom: 0;
}

.or {
	align-self: center;
	margin-bottom: 0;
	text-transform: uppercase;
	width: 48px;
	height: 48px;
	display: block;
	background-color: #d5e1f8;
	border-radius: 50%;
	line-height: 48px;
	color: $brand-secondary;
	color: #000;
	margin: 30px 0;
}

.financing_options {
	border-top: 1px solid #fff;
	padding: 0 16px;
	padding-top: 28px;
	padding-bottom: 28px;
	@include sm-up {
		padding: 0 28px;
		padding-top: 28px;
		padding-bottom: 28px;
	}
}

.financing_options_cart {
	border-top: 1px solid #fff;
	padding: 28px;

	.payment_option {
		.trigger_p {
			font-size: 10px !important;
		}

		.finance_text {
			font-size: 10px !important;
			padding-left: 5px !important;
			padding-right: 2px !important;
			line-height: 14px !important;
			padding-left: 18px !important;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.trigger_grey {
	color: $type-blue3 !important;
}

.payment_option {
	display: grid;
	align-items: center;
	grid-template-areas:
		'img text'
		'img link';
	@include sm-up {
		grid-template-columns: 70px 1fr max-content;
		grid-template-areas: 'img text link';
	}
	margin-bottom: 30px;

	img {
		width: 100%;
		display: block;
		object-fit: contain;
		grid-area: img;

		@include xs-only {
			max-width: 50px !important;
		}
	}

	p {
		margin-bottom: 0;
	}

	.trigger_p {
		text-decoration: none;
		color: $brand-primary;
		cursor: pointer;
		text-decoration: underline;
		grid-area: link;
		@include xs-only {
			font-size: 10px;
			padding-left: 18px;
		}
	}

	.finance_container {
		padding-left: 20px;
		display: grid;
		grid-area: text;
		@include md-up {
			grid-template-columns: 1fr max-content;
		}

		.finance_text {
			@include xs-only {
				font-size: 12px;
			}
		}
	}
}

.payment_option_2 {
	margin-bottom: 0;
}

.price_subtext {
	font-weight: 300;
	font-size: 12px;
	margin-bottom: 0;
	opacity: 0.7;
}

.money_wrapper {
	padding: 15px;
	text-align: center;
}

.money_separator {
	border-bottom: 1px solid #e5e5e5;
	background: #edeef1;
	box-shadow: 0px 1px 0px 0px #fff;
	height: 1px;
	overflow: visible;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #4a4a4b;
	font-size: 12px;
	position: relative;
	margin: 10px auto;
	width: 90%;

	> span {
		background-color: #f7f8fa;
		position: absolute;
		margin: 0 auto;
		z-index: 2;
		width: 30px;
		width: 35px;
	}
}

.money_banner_content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	> * {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		> p {
			margin: 0;
		}
	}

	a,
	:global(.truemed-instructions-open) {
		font-size: 10px;
		font-weight: 500;
		color: #0249a0;
		font-family: NeueMontreal, sans-serif;
		text-decoration: none;
		line-height: 1.4;
	}

	img {
		width: 60px;
	}

	:global(.truemed-instructions-link-text > span) {
		display: none !important;
	}
}
