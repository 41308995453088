@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	color: $black;

	margin-bottom: 2rem;
}

.product_title {
	margin-bottom: 0.25rem;
}

.financing_info {
	svg {
		max-height: 1em;
		margin-inline: 0.2ch;
	}

	.klarna_logo {
		width: 4.6em;
	}

	margin-top: 0.5rem;
	display: block;
	color: #999;
}

.mobile_visual {
	@include md-up {
		display: none;
	}
}

.sticky_wrapper_mobile {
	background: $white;
	position: sticky;

	z-index: 10;
	top: 0;

	@include md-up {
		display: none;
	}

	margin-bottom: $spacing-large-desktop;

	transition: top $promo-transition-time $promo-transition-ease;
}

.reviews {
	width: 100%;

	svg {
		height: 1em;
	}

	display: inline-flex;
	align-items: center;
	gap: 0.5rem;

	margin-bottom: 1.5rem;
	margin-top: 0.75rem;

	@include sm-down {
		margin-bottom: 1.25rem;
	}

	p {
		align-self: flex-end;
	}
}

.stars_container {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: $text-grey-dark;

	svg {
		height: 1rem;
		width: 5rem;
	}

	p {
		user-select: none;
	}

	&[data-hoverable='true'] {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
