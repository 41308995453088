@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;
}

.wrapper {
	max-width: 90rem;
	margin-inline: auto;

	padding: 7rem 4rem;

	@include sm-down {
		padding: 4rem 1.5rem;
		gap: 2rem;
	}

	display: grid;
	grid-template-columns: 1fr 1fr;

	align-items: center;
	justify-content: center;

	gap: 5rem;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.visuals {
	max-height: 40rem;
	height: 100%;

	// TODO, this isn't ideal. We do this because, by default, the visuals render at 100vh.
	//  But here we want to have a regular section height.
	> div > ul {
		height: 100% !important;
	}

	border-radius: 0.25rem;
	contain: paint;

	@include sm-down {
		height: 30rem;
	}

	@include xs-only {
		height: 25rem;
	}
}

.reviews {
	width: 100%;

	svg {
		height: 1em;
	}

	display: inline-flex;
	align-items: center;
	gap: 0.5rem;

	margin-bottom: 1.5rem;

	@include sm-down {
		margin-bottom: 1.25rem;
	}
}

.description {
	max-width: 60ch;

	margin-bottom: 1.5rem !important;

	min-height: 4.5em;

	@include sm-down {
		margin-bottom: 1.25rem !important;
	}
}

.model_selector {
	margin-bottom: 1.5rem;
	@include sm-down {
		margin-bottom: 1.25rem;
	}
}

.size_selector {
	margin-bottom: 2rem;
}

.model_selector_buttons {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.model_selector_button {
	padding: 0.75rem 1.25rem;

	border-radius: 0.25rem;
	border: 1px solid #a0a0a0;

	&:disabled {
		opacity: 0.25;
	}
}

.model_selector_button_selected {
	color: $white;
	background-color: $black;
}

.add_to_cart_button {
	width: 100%;

	margin-bottom: 1.25rem;
}

.shipping_text {
	padding-top: 1rem;
	text-align: center;
}

.shop_content h3 {
	margin-bottom: 1rem;
}

.size_selector_buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	@include xs-only {
		grid-template-columns: 2fr 1fr;
	}
}

.size_guide_button {
	text-decoration: underline;
	color: #999;

	width: auto;

	justify-items: start;
}

.fsa {
	color: #636363;
	text-align: center;

	a {
		font-weight: 500;
		text-decoration: underline;
		color: #636363;
	}
}

.small_benefits {
	list-style: none;
	margin: 0.5rem 0 0;
	padding: 0;

	text-align: center;

	display: flex;
	justify-content: center;
	gap: 1.25rem;

	p {
		display: flex;
		align-items: center;

		color: #636363;
	}

	svg {
		height: 1em;
		margin-right: 0.5rem;
		width: 1em;
	}
}

.asterisk {
	color: #636363;
	margin-top: 2.5rem;
	text-align: center;
}
