@import 'styles/constants';
@import 'styles/mixins/media-queries';

@keyframes scale {
	30% {
		-webkit-transform: scale(0.3);
		transform: scale(0.3);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.ball_pulse > div:nth-child(1) {
	animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.ball_pulse > div:nth-child(2) {
	animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.ball_pulse > div:nth-child(3) {
	animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.ball_pulse > div {
	width: 15px;
	height: 15px;
	animation-fill-mode: both;
}

.ball_pulse > div {
	background-color: $brand-primary;
	border-radius: 100%;
	margin: 7px;
	display: inline-block;
}

.ball_pulse {
	margin: auto;
}

.loader {
	position: absolute;
	width: 100%;
	height: 100%;
	inset: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	z-index: 99999;
	background-color: rgba(247, 248, 250, 0.9);
}
