@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-off-white;
	color: $text-black;

	display: grid;
	grid-template-columns: 1fr 1fr;

	height: calc(100svh - $promo-height - 70px);

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: 18rem 1fr;
		align-items: start;
		min-height: unset;

		height: unset;
	}

	transition: height 0.25s ease;

	align-items: center;
}

.header_content {
	padding: 4rem;

	@include sm-down {
		order: 2;
		padding: 4rem 1.25rem;
	}
}

.header_content header {
	a,
	button {
		margin-top: 2.5rem;

		@include sm-down {
			margin-top: 0;
		}
	}

	h1 {
		max-width: 15ch;
		margin-top: 0.5rem;
	}

	p {
		max-width: 32ch;
		text-wrap: balance;
	}

	margin-bottom: 7rem;

	@include sm-down {
		margin-bottom: 3rem;
	}
}

.cta_container {
	display: flex;
	justify-content: flex-start;
	gap: 1.25rem;

	@include sm-down {
		flex-direction: column;
		gap: 0.5rem;
		margin-top: 1.5rem;
	}
}

.eyebrow {
	color: $text-grey-dark;
}

.image {
	position: relative;
	width: 100%;
	height: 100%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-position: 65%;

		@include sm-down {
			object-position: 100%;
		}
	}
}

.video_container {
	position: relative;
	width: 100%;
	height: 100%;

	.video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 0% 50%;

		@include sm-down {
			object-position: center;
		}
	}
}

.modal_video_container {
	height: 100%;
	width: 100%;

	video {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.list {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	margin: 1.4rem 0 1rem;

	@media (max-height: 650px) {
		display: none;
	}

	p {
		display: inline-flex;
		align-items: center;
		gap: 0.5rem;

		@include body2;
	}

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}
