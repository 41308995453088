@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.affirm {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	line-height: 20px;

	:global(.affirm-as-low-as) {
		span {
			display: none;
		}
	}

	:global(.affirm-line-1) {
		font-family: var(--neue-montreal), sans-serif;
		font-style: normal;
		font-weight: bold;
		line-height: 22px;
		display: block;
		margin-bottom: 10px;
		text-transform: capitalize;
		@include xs_only {
			font-size: 14px;
		}
	}
	:global(.affirm-line-2) {
		display: block;
		font-size: 16px;
		line-height: 18px;
	}
	br {
		display: none;
	}

	:global(.affirm-modal-trigger) {
		color: #000 !important;
		font-size: 12px;
	}

	&:global(.newfinancing) {
		margin: 0 6px;
		:global(.affirm-modal-trigger) {
			display: none;
		}
		:global(.affirm-line-1) {
			display: none;
		}
		:global(.affirm-line-2) {
			display: block;
			width: 100%;
		}
	}
	> span {
		display: none;
	}
	> span:global(.affirm-ala-price) + span {
		font-size: 16px !important;
		display: inline-block !important;
		margin: 0px !important;
		overflow: hidden;
		white-space: nowrap;
	}
}

.affirm :global(.affirm-ala-price) {
	font-family: var(--neue-montreal), sans-serif;
	display: inline-block !important;
	font-size: 16px;
	line-height: 18px;
	font-weight: bold;
	letter-spacing: 0.83px;
	font-style: normal;
}

.monthly_price {
	margin-bottom: 0;
	margin-left: 10px;
	span {
		font-size: 16px;
		line-height: 18px;
		font-weight: bold;
		letter-spacing: 0.83px;

		@include xs-only {
			font-size: 14px;
		}
	}
}
