@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	display: flex;
	flex-direction: column;
}

.videoContainer {
	width: 100vw;
	overflow: hidden;
	position: relative;

	aspect-ratio: 16/9;

	max-height: 65rem;

	@include sm-down {
		aspect-ratio: 9/16;
		max-height: 50rem;
	}
}

.video {
	display: block;
	isolation: isolate;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	object-fit: cover;
}

.videoContainer:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
	opacity: 0.5;
	z-index: 1;
}

.floatingInfo {
	display: flex;
	flex-direction: column;
	color: $white;
	position: absolute;

	z-index: 2;

	max-width: $max-width;

	top: 0;
	bottom: 0;
	justify-content: center;

	left: 4rem;
	right: 4rem;
	margin: auto;

	.eyebrow {
		opacity: 0.65;
	}

	.title {
		max-width: 20ch;
	}

	.text {
		color: #f2f2f2;
		max-width: 40ch;
	}

	@include sm-down {
		height: calc(100% - 5rem);
		margin-top: 5rem;

		align-items: center;

		text-align: center;
	}
}

.elevationCardSleeping {
	opacity: 0;
}

.elevation {
	margin-top: 4rem;
	display: flex;
	flex-direction: row;
	padding: 2rem 2.5rem;

	align-items: flex-start;
	gap: 1.5rem;
	border-radius: 25px;
	box-shadow: hsla(0, 0%, 100%, 0.3) 1.25px 0 1px 0 inset, hsla(0, 0%, 100%, 0.3) -1.5px 0 1px 0 inset, hsla(0, 0%, 100%, 0.1) 0 -1.5px 1px inset, hsla(0, 0%, 100%, 1) 0 1.5px 1px inset;
	backdrop-filter: blur(6px);
	width: 350px;

	.images {
		display: flex;
		flex-direction: row;
		width: 120px;
		img {
			width: 118px;
			opacity: 1;
		}
	}

	.angles {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2.5rem;
	}
	.angleTitle {
		@include body5;
		color: #dbdbdb;
	}
	.angleValue {
		@include headline6;
		color: $white;
	}
	@include sm-down {
		margin-top: auto;
		margin-bottom: 7rem;
	}
}

.video {
	@include sm-down {
		display: none;
	}
}

.videoMob {
	display: none;
	@include sm-down {
		display: inherit;
		video {
			object-fit: cover;
			height: 100svh;
			max-height: 50rem;
			width: 100vw;
		}
	}
}
