@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	h3,
	h4 {
		@include headline9;
	}

	h2 {
		@include headline8;
		text-align: center;
	}
}

.navigation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;

	position: fixed;
	left: 1rem;
	right: 1rem;
	top: 1rem;

	z-index: 100;

	.icon {
		width: 1.5rem;
		margin: auto 0;
	}
	.title {
		margin: auto;

		h3 {
			@include headline9;
		}
	}
}

.icon_disabled {
	visibility: hidden;
	pointer-events: none;
}
