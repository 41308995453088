@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;

	@include sm-down {
		padding-left: 0;
		padding-right: 0;
	}
}

.content {
	max-width: $max-width;
	margin-inline: auto;
}

.container header {
	text-align: left;

	margin-bottom: 4rem;

	@include sm-down {
		text-align: center;
		margin-inline: auto;

		padding: 0 $spacing-medium-mobile;
	}

	p {
		color: $text-grey-dark;

		max-width: 50ch;

		@include sm-down {
			margin-inline: auto;
		}
	}
}

.tabs {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	@include xs-only {
		grid-template-columns: auto;
		grid-template-rows: 1fr 1fr 1fr 1fr;
	}
}

.tab {
	position: relative;
	box-shadow: #a6a6a6 0 2px 0 inset;
	font-weight: 400;
	padding: 1.5rem 0;
	cursor: pointer;

	opacity: 0.5;

	transition: opacity 0.25s ease-out;

	@include xs-only {
		width: 100%;
	}
}

.tabSelected {
	opacity: 1;
}

.tab_progress {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	width: 100%;
	height: 2px;
	background-color: $black;

	transition: opacity 0.25s ease-out;
	z-index: 1;

	opacity: 0;
}

.tab_description {
	max-width: min(23ch, 90%);
}

.tabContainer {
	@include sm-down {
		display: none;
	}
}

$media-height-desktop: 35rem;
$media-height-mobile: 25rem;
.media {
	display: flex;
	flex-direction: row;
	align-items: center;

	@include sm-down {
		height: $media-height-mobile;
	}
	height: $media-height-desktop;
}

.sliderContainer {
	@include md-up {
		display: none;
	}

	contain: paint;

	width: 100%;
}

.media_container {
	position: relative;

	display: grid;
	grid-template-areas: 'center';

	margin-bottom: 4rem;

	border-radius: 16px;

	background: #f2f2f2;

	> * {
		grid-area: center;
		transition: opacity 0.25s ease-out;
		position: relative;
		@include sm-down {
			height: $media-height-mobile;
		}
		height: $media-height-desktop;
		width: 100%;

		img,
		figure,
		video {
			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			border-radius: 16px;
		}
	}

	div:last-child {
		img {
			scale: 0.9;
			object-fit: contain !important;
		}
	}
}

.slide {
	width: 70vw;
	aspect-ratio: 3 / 4;
	max-width: 30rem;

	border-radius: 16px;

	position: relative;

	img,
	video,
	figure {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 16px;
		object-fit: cover;
	}

	video {
		object-position: 20%;
	}
}

.sliderDescription {
	max-width: 300px;
}

.video:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background: rgba(0, 0, 0, 0.06);
	opacity: 1;
}

.slider_outer {
	padding-left: 2rem;
}

.slider {
	list-style: none;

	display: flex;
	flex-wrap: nowrap;

	overflow: visible;

	gap: 1rem;

	margin-bottom: 2rem;

	li {
		transition: all 0.25s ease-out;
	}
}

.descriptions {
	display: grid;

	width: 100%;
	grid-template-areas: 'center';

	align-items: center;

	list-style: none;

	margin-bottom: 0;
	padding: 0 2rem;

	li {
		transition: opacity 0.25s ease-out;
		grid-area: center;
	}

	p {
		max-width: 23ch;
		margin-inline: auto;

		color: $black;
	}
}

.dots {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	align-items: center;

	list-style: none;

	padding: 0 2rem;

	margin-bottom: 0;

	gap: 0.5rem;

	button {
		background: $black;
		border: none;
		border-radius: 50%;
		padding: 0.25rem;
		cursor: pointer;
	}
}
