@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: #000;
	color: $white;

	padding: 8rem 0;

	@include sm-down {
		padding: 6rem 0;
	}
}

.header {
	text-align: center;

	p {
		margin-bottom: 2rem;
	}

	h2 {
		@include headline1;

		font-size: clamp(5rem, 24vw, 19rem);

		background: linear-gradient(180deg, #fff 11.2%, rgba(255, 255, 255, 0.05) 94.46%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		margin-bottom: 3rem;
		position: relative;
		z-index: 2;
	}

	margin-bottom: 6rem;

	@include sm-down {
		margin-bottom: 2.5rem;

		p {
			margin-bottom: 0.5rem;
		}
	}
}

.hero_image {
	margin-top: clamp(-16rem, -16vw, -4rem);
	margin-inline: auto;
	max-width: 72rem;
	display: block;
	width: 100%;
}

.description {
	max-width: 1065px;
	margin-inline: auto;
	text-align: center;

	margin-bottom: 3.5rem;

	display: flex;
	flex-direction: column;
	gap: 30px;

	@include sm-down {
		margin-inline: 1rem;
	}
}

.cta_container {
	margin-bottom: 8rem;
	display: flex;
	justify-content: center;
}

.purple_button {
	position: relative;

	color: $white;
	@include body4;

	span {
		display: flex;
		align-items: center;
		gap: 0.25rem;

		color: $white;
	}

	padding: 1rem 1.5rem !important;

	border-radius: 0.5rem;

	background: radial-gradient(148.84% 194.77% at 57.56% 65.69%, #9d8bd2 0%, #231844 100%) !important;

	margin-inline: auto;

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 0.5rem;

		top: 0;
		left: 0;

		height: 100%;
		width: 100%;

		z-index: 1;
	}

	&::before {
		border: 1px solid rgba(255, 255, 255, 0.54);
	}

	&::after {
		box-shadow: 0 4px 44px 0 #ac5cdd inset;
	}
}

.features {
	margin: 0 2rem;
}

.features_header {
	text-align: center;

	h3 {
		max-width: 25ch;
		margin-inline: auto;
	}

	margin-bottom: 5.5rem;

	@include sm-down {
		margin-bottom: 4rem;
	}
}

.features_list {
	display: grid;
	max-width: 855px;
	margin-inline: auto;
	grid-template-columns: repeat(4, minmax(0, auto));
	gap: 2.5rem;
	justify-content: space-evenly;
	list-style: none;

	li {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 8rem;
		min-width: 12rem;

		text-align: center;
	}

	p {
		color: $text-grey-light;

		max-width: 8ch;
		margin-top: 1rem;
	}

	@include sm-down {
		max-width: 30rem;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin: 0 2rem;
		margin-inline: auto;
		column-gap: 1rem;
		row-gap: 2rem;
		padding-left: 0;

		justify-items: center;

		li {
			text-align: center;
			align-items: center;
		}

		svg {
			width: 2rem;
			height: 2rem;
		}

		p {
			margin-top: 1rem;
		}
	}
}
