@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.root {
	background-color: $background-off-white;
	color: $text-black;

	@include sm-down {
		padding-left: 0;
		padding-right: 0;
	}

	&.hacky_container {
		background-color: $white;
	}
}

.container {
	max-width: $max-width;
	margin: 0 auto;

	display: grid;
	grid-template-columns: 1fr 1fr;

	&.hacky_container {
		grid-template-columns: 1fr 1.5fr;
		background-color: $white;

		h1 {
			max-width: 13ch;
		}

		@include sm-down {
			grid-template-columns: 1fr;
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		row-gap: 0;
		align-items: start;
		min-height: unset;
		height: unset;
	}

	transition: height 0.25s ease;

	align-items: center;
}

.header_content {
	@include section-padding-y;
	padding-left: 4rem;
	padding-right: 0;

	@include sm-down {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.image_container {
	height: 100%;
	width: 100%;

	* {
		height: 100%;
		width: 100%;
	}
}

.header_content header {
	a,
	button {
		margin-top: 2.5rem;

		@include sm-down {
			margin-top: 0;
		}
	}

	h1 {
		max-width: 14ch;
		margin-top: 0.5rem;
	}

	p {
		max-width: 32ch;
	}
}

.cta_container {
	display: flex;
	justify-content: flex-start;
	gap: 1.25rem;

	@include sm-down {
		flex-direction: column;
		gap: 0.5rem;
		margin-top: 1.5rem;
	}
}

.video_container {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 50vh;

	@include sm-down {
		min-height: 450px;
		overflow: hidden;
	}

	> svg {
		position: absolute;
		z-index: 2;
		bottom: 50px;
		left: 50px;

		@include sm-down {
			left: auto;
			bottom: 15px;
			right: 15px;
			max-height: 50%;
			max-width: 55%;
		}
	}
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

	@include sm-down {
		position: static;
	}

	&.hacky_video {
		object-fit: cover;
		object-position: left bottom;
		width: calc(100vw - 450px);

		@include sm-down {
			width: 100%;
		}
	}
}

.modal_video_container {
	height: 100%;
	width: 100%;

	video {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.list {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	margin: 1.4rem 0 1rem;

	@media (max-height: 650px) {
		display: none;
	}

	p {
		display: inline-flex;
		align-items: center;
		gap: 0.5rem;

		@include body2;
	}

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}
